












































































































import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { Course } from "@/types";
import AddRemoveCategory from "./AddRemoveCategory.vue";

@Component({
  metaInfo() {
    return {
      title: "Eğitimler / Admin"
    };
  },
  components: {
    AddRemoveCategory
  }
})
export default class AdminCoursesView extends Vue {
  search = "";
  loading = false;
  headers = [
    {
      text: "Ders Adı",
      align: "start",
      filterable: true,
      value: "name"
    },
    { text: "Eğitmen", value: "instructors" },
    { text: "Ücret", value: "price" },
    { text: "Durum", value: "disabled", width: "90px" },
    { text: "", value: "actions", sortable: false, width: "50px" }
  ];
  items = [] as Course[];
  selectedItem = {};
  dialog = false;
  dialogContent = "";

  async getItems() {
    try {
      this.loading = true;

      var res = await axios.get("/api/course/get-all", {
        headers: this.$store.getters["auth/requestAuthHeader"]
      });

      this.items = res.data;
    } catch (e: unknown) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Ders listesi alınamadı.`
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteCourse(course: Course) {
    const confirmation = await this.$confirm("Bu işlem geri alınamaz. Eğitimi silmek istediğinizden emin misiniz?", { title: "Emin misini?", color: "warning" });

    if (!confirmation) return;

    try {
      // Eğitimi sil
      const authHeader = this.$store.getters["auth/requestAuthHeader"];
      var res = await axios.delete(`/api/course/${course.id}`, {
        headers: authHeader
      });

      this.$notify({
        type: "success",
        title: "İşlem Tamam",
        text: "Eğitim başarıyla silindi."
      });

      await this.getItems();
    } catch (e) {
      this.$notify({
        type: "eror",
        title: "Hata!",
        text: "Eğitim silinemedi."
      });

      throw e;
    }
  }

  async mounted() {
    await this.getItems();
  }
}
