























































import { Vue, Component, Prop } from "vue-property-decorator";

interface Category {
  id: string;
  name: string;
}

interface Course {
  id: string;
  name: string;
}

@Component<AddRemoveCategory>({
  watch: {
    async course() {
      await this.getAllCategories();
      await this.getSavedCategories();
    }
  }
})
export default class AddRemoveCategory extends Vue {
  @Prop() course!: Course;

  disabled = false;

  categories = [] as Category[];

  savedCategories = [] as Category[];

  selectedCategories = [] as string[];

  close() {
    this.selectedCategories = [];
    this.$emit("close");
  }

  async getAllCategories() {
    this.categories = [];

    try {
      // const categoryQs = await firebase
      //   .firestore()
      //   .collection("/apps/kahev-akademi/categories")
      //   .orderBy("name")
      //   .get();
      // categoryQs.forEach(catDoc => {
      //   this.categories.push({
      //     id: catDoc.id,
      //     name: catDoc.data().name
      //   });
      // });
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Kategoriler alınamadı."
      });

      throw e;
    }
  }

  async getSavedCategories() {
    this.savedCategories = [];
    this.selectedCategories = [];

    try {
      // const courseDoc = await firebase
      //   .firestore()
      //   .doc(`/apps/kahev-akademi/courses/${this.course.id}`)
      //   .get();
      // if (courseDoc.exists) {
      //   const catIds = courseDoc.data()?.categories as string[];
      //   catIds.forEach(id => {
      //     const i = this.categories.findIndex(c => c.id == id);
      //     const category = this.categories[i];
      //     this.savedCategories.push(category);
      //     this.selectedCategories.push(id);
      //   });
      // }
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Kayıtlı kategoriler alınamadı."
      });

      throw e;
    }
  }

  async addCategories() {
    const confirmation = await this.$confirm("Seçili kategorileri eklemek istediğinizden emin misiniz?", { title: "Emin misini?", color: "warning" });

    if (!confirmation) return;

    try {
      // // Kategorileri kaydet
      // await firebase
      //   .firestore()
      //   .doc(`/apps/kahev-akademi/courses/${this.course.id}`)
      //   .update({
      //     categories: this.selectedCategories
      //   });
      // await this.getSavedCategories();
      // // Arka planda kategorilerin eğitim sayılarını güncelle
      // this.selectedCategories.forEach(cat => {
      //   firebase
      //     .firestore()
      //     .doc(`/apps/kahev-akademi/categories/${cat}`)
      //     .update({
      //       count: firebase.firestore.FieldValue.increment(1)
      //     });
      // });
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Kategoriler eklenemedi."
      });

      throw e;
    }
  }

  async removeCategory(categoryId: string) {
    const confirmation = await this.$confirm("Bu işlem geri alınamaz. Seçili kategoriyi silmek istediğinizden emin misiniz?", { title: "Emin misini?", color: "warning" });

    if (!confirmation) return;

    try {
      // // Kategoriyi hafızadan sil
      // const i = this.selectedCategories.findIndex(c => c == categoryId);
      // this.selectedCategories.splice(i, 1);
      // // Kategorileri kaydet
      // await firebase
      //   .firestore()
      //   .doc(`/apps/kahev-akademi/courses/${this.course.id}`)
      //   .update({
      //     categories: this.selectedCategories
      //   });
      // await this.getSavedCategories();
      // // Arka planda kategorilerin eğitim sayılarını güncelle
      // firebase
      //   .firestore()
      //   .doc(`/apps/kahev-akademi/categories/${categoryId}`)
      //   .update({
      //     count: firebase.firestore.FieldValue.increment(-1)
      //   });
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Kategori silinemedi."
      });

      throw e;
    }
  }

  async mounted() {
    await this.getAllCategories();
    await this.getSavedCategories();
  }
}
